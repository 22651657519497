<template>
  <ion-app id="ion-app" class="max-w-screen-2xl mx-auto">
    <ion-tabs @ionTabsDidChange="tabChange">
      <ion-router-outlet :animated="true"></ion-router-outlet>
      <ion-tab-bar slot="bottom" v-show="isBarVisible">
        <ion-tab-button
          v-for="link in navigation"
          :key="link.to"
          :tab="link.to"
          :href="link.href"
        >
          <div class="relative mt-1">
            <ion-icon :icon="link.icon" class="text-xl"></ion-icon>

            <Transition name="badge">
              <div
                v-show="link.badge"
                class="text-white text-xxs absolute right-1.5 -top-1 px-1 leading-none py-1 rounded-full bg-lrt-orange -mr-4"
              ></div>
            </Transition>
          </div>
          <ion-label>{{ link.label }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <ion-toast
        color="light"
        position="bottom"
        :is-open="useToast.isVisible"
        @didDismiss="
          () => {
            useToast.hideToast();
          }
        "
        :message="useToast.message"
        :duration="2000"
      ></ion-toast>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonToast,
  IonIcon
} from '@ionic/vue';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { onMounted, onUnmounted } from 'vue';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { checkAppStatus } from '@/helperFns/appStatus';
import { addPushListeners } from "@/helperFns/pushNotifications";
import { useToast } from '@/composables/useToast';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonToast,
    IonIcon
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const hasUnreadMessages = computed(() => {
      return store.getters.hasUnreadMessages || false;
    });

    const isBarVisible = computed(() => {
      return !route.meta.isMenuHidden;
    });

    const navigation = ref([
      {
        label: 'Academy',
        to: 'academy',
        href: '/academy',
        badge: false,
        icon: 'assets/images/menu-icons/icon-academy.svg'
      },
      {
        label: 'Aktivitet',
        to: 'activity',
        href: '/activity',
        badge: false,
        icon: 'assets/images/menu-icons/icon-activity.svg'
      },
      {
        label: 'Utbildning',
        to: 'education',
        href: '/education',
        badge: false,
        icon: 'assets/images/menu-icons/icon-education.svg'
      },
      {
        label: 'Klubb',
        to: 'club',
        href: '/club',
        badge: false,
        icon: 'assets/images/menu-icons/icon-club.svg'
      },
      {
        label: 'Chatt',
        to: 'chat',
        href: '/chat',
        badge: hasUnreadMessages,
        icon: 'assets/images/menu-icons/icon-chat.svg'
      }
    ]);

    const setStatusBar = async () => {
      if (Capacitor.isPluginAvailable('StatusBar')) {
        await StatusBar.setStyle({ style: Style.Dark });
      }
    };

    const fetchUpdates = async () => {
      try {
        await checkAppStatus();
      } catch (error) {
        console.log('Error checking app status', error);
        return;
      }
    };

    const currentTab = ref(null);

    const tabChange = async e => {
      currentTab.value = e.tab;
    };

    let intervalUpdates = null;

    onMounted(() => {
      addPushListeners();

      setStatusBar();

      intervalUpdates = setInterval(() => {
        if (isBarVisible.value && currentTab.value !== 'chat') {
          fetchUpdates();
        }
      }, 3000);
    });

    onUnmounted(() => {
      // Remove interval when unmounted
      intervalUpdates && clearInterval(intervalUpdates);
    });

    return {
      navigation,
      hasUnreadMessages,
      isBarVisible,
      currentTab,
      tabChange,
      useToast
    };
  }
});
</script>

<style scoped>
.badge-enter-active,
.badge-leave-active {
  transition: transform 0.2s ease;
}

.badge-enter-from,
.badge-leave-to {
  transform: scale(0);
}
ion-label {
  font-size: 0.6rem;
}
ion-tab-bar {
  --color: #a9a9a9;
  --color-selected: #fff !important;
}
</style>

<style>
ion-page,
ion-content,
ion-toolbar,
ion-app,
body {
  --background: #000000;
  --color: #ffffff;
}
ion-button {
  text-transform: uppercase;
  letter-spacing: .025rem;
  --border-radius: 0;
}
ion-tab-bar {
  --background: #000000;
}
ion-input,
ion-textarea,
ion-select {
  --padding-bottom: 0.75rem !important;
  --padding-end: 0.75rem !important;
  --padding-start: 0.75rem !important;
  --padding-top: 0.75rem !important;
  font-size: 12px !important;
  border: solid 1px #333333;
  border-radius: 4px;
}
ion-item {
  --detail-icon-opacity: 1;
  --padding-bottom: .5rem;
  --padding-top: .5rem;
  --padding-end: .5rem;
}
/* Since we have added a border we need the padding to stay */
.native-input.sc-ion-input-ios {
  padding-left: var(--padding-start);
  padding-right: var(--padding-end);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
}
</style>
