export default [
  {
    path: '/integritetspolicy',
    name: 'integritetspolicy',
    component: () => import('@/views/info/Integritetspolicy.vue'),
    meta: {
      auth: false,
      isMenuHidden: true
    }
  },
  {
    path: '/anvandarpolicy',
    name: 'anvandarpolicy',
    component: () => import('@/views/info/Anvandarpolicy.vue'),
    meta: {
      auth: false,
      isMenuHidden: true
    }
  }
];