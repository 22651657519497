import { reactive } from 'vue';

export const useToast = reactive({
  isVisible: false,
  message: 'LRT Club',
  showToast(message) {
    this.message = message;
    this.isVisible = true;
  },
  hideToast() {
    this.message = '';
    this.isVisible = false;
  }
});
