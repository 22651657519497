import axios from "axios";

const state = {}

const getters = {}

const mutations = {}

const actions = {
  async uploadImage(context, request = {}) {
    try {
      let file = request.file;
      delete request.file; // Make sure file is not sent twice

      // Create file model in database
      const { data } = await axios.post("educator/files", request)

      // Upload image to S3
      await axios.put(data.url, file);

      // Check if image is uploaded
      await axios.post(`educator/files/uploaded/${data.id}`);

      return data.id;
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  },
}

export { state, getters, mutations, actions }