import { alertController } from "@ionic/vue";
import router from "../router";

const alertText = async text => {
  // Check if text is not string, if so get the message from the error object
  if (typeof text !== "string") {
    text = text.response?.data?.message || 'Ett fel uppstod. Se över uppgifterna och försök igen.';
  }

  const alert = await alertController.create({
    message: text,
    buttons: [
      {
        text: "OK",
        handler: () => {}
      }
    ]
  });
  await alert.present();
};

const alertBackOnDismiss = async text => {
  const alert = await alertController.create({
    message: text,
    buttons: [
      {
        text: "OK",
        handler: () => {
          router.back();
        }
      }
    ]
  });
  await alert.present();
};

const alertNavigateOnDismiss = async (text, url = "/", replace = false) => {
  const alert = await alertController.create({
    message: text,
    buttons: [
      {
        text: "OK",
        handler: () => {
          replace ? router.replace(url) : router.push(url);
        }
      }
    ]
  });
  await alert.present();
};

const alertWithHandle = async (text) => {
  const alert = await alertController.create({
    message: text,
    buttons: [
      {
        text: "Avbryt",
        role: "cancel"
      },
      {
        text: "OK",
        role: "confirm",
      }
    ]
  });
  alert.present();
  return alert.onDidDismiss();
};

export { alertBackOnDismiss, alertText, alertNavigateOnDismiss, alertWithHandle };
