import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import axios from 'axios';
import store from '../store';
import router from '../router';

const addPushListeners = () => {
  if (Capacitor.isPluginAvailable('PushNotifications')) {
    PushNotifications.addListener('registration', async (token) => {
      try {
        await axios.put(`/educator/notifications/set-token`, {
          fcm_token: token.value,
        });
      } catch (error) {
        console.log('error', error);
      }

        console.log('Push registration success, token: ' + token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Push received: ' + JSON.stringify(notification));
    });

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        if(notification.notification.data.action === 'OPEN_CHAT') {
          // Navigate to chat
          if (router && notification.notification.data.resource_id) {
            router.push(`/chat/${notification.notification.data.resource_id}`);
          }
        }
      }
    );
  }
};

const initializePushNotifications = async () => {
  if (Capacitor.isPluginAvailable('PushNotifications')) {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    const result = await PushNotifications.requestPermissions();

    addPushListeners();

    if (result.receive !== 'granted') {
      store.dispatch('updateNewsNotifications', false);
      store.dispatch('updateChatNotifications', false);
      return false;
    }

    await PushNotifications.register();

    store.dispatch('updateNewsNotifications', true);
    store.dispatch('updateChatNotifications', true);
  } else {
    console.log('Push notifications not available');
  }
};

const disablePushNotifications = () => {
  PushNotifications.removeAllListeners().then(() => {
      console.log('Push notification listeners removed');
  });

  // Send empty token to backend to remove it
  axios.put(`/educator/notifications/set-token`, {
    fcm_token: '',
  }).catch((error) => {
    console.log('error', error);
  });
};

export {
  initializePushNotifications,
  disablePushNotifications,
  addPushListeners,
};
