import { createStore } from "vuex";

import * as app from "./modules/app";
import * as auth from "./modules/auth";
import * as agreements from "./modules/agreements";
import * as club from "./modules/club";
import * as profile from "./modules/profile";
import * as academy from "./modules/academy";
import * as products from "./modules/products";
import * as availability from "./modules/availability";
import * as fileUpload from "./modules/fileUpload";
import * as presentation from "./modules/presentation";
import * as settings from "./modules/settings";
import * as groups from "./modules/groups";
import * as organizations from "./modules/organizations";
import * as chat from "./modules/chat";

const store = createStore({
  modules: {
    app,
    auth,
    agreements,
    club,
    profile,
    academy,
    products,
    availability,
    fileUpload,
    presentation,
    settings,
    groups,
    organizations,
    chat
  },
  state: {
    isLoading: false,
  },
  getters: {
    isLoading({ isLoading }) {
      return isLoading;
    },
  },
  mutations: {
    isLoading(state, data) {
      state.isLoading = data;
    },
  },
  actions: {},
});

export default store;