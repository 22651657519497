export default [
  {
    path: '/agreements',
    name: 'agreements-list',
    component: () => import('@/views/agreements/List.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/agreements/:id',
    component: () => import('@/views/agreements/Detail.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  }
];