import packageInfo from '../package.json';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { IonicVue } from '@ionic/vue';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import LrtLoadingOverlay from '@/components/base/LrtLoadingOverlay';
import VueGoogleMaps from 'vue-google-maps-community-fork';
import { VueMaskDirective } from 'v-mask';
// Fix for useing v-mask in Vue3
const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

/* Bugsnag */
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';
import './assets/tailwind.css';

// To make Axios config available globally
import './helperFns/axios';

const initBugnsag = () => {
  if (process.env.NODE_ENV === 'production') {
    Bugsnag.start({
      appVersion: packageInfo.version,
      apiKey: process.env.VUE_APP_BUGSNAG,
      plugins: [new BugsnagPluginVue()],
      enabledReleaseStages: ['production'],
      onError: function (event) {
        if (store.getters.currentUser) {
          const { id, email } = store.getters.currentUser;
          const { first_name, last_name } = store.getters.currentUser.profile;
          event.setUser(id, email, `${first_name} ${last_name}`);
        }
      },
    });
  } else {
    Bugsnag.start({
      appVersion: packageInfo.version,
      logger: null, // comment out to report errors when developing
      apiKey: process.env.VUE_APP_BUGSNAG,
      plugins: [new BugsnagPluginVue()],
      enabledReleaseStages: ['production'],
      onError: function (event) {
        if (store.getters.currentUser) {
          const { id, email } = store.getters.currentUser;
          const { first_name, last_name } = store.getters.currentUser.profile;
          event.setUser(id, email, `${first_name} ${last_name}`);
        }
      },
    });
  }
};

initBugnsag();

const bugsnagVue = Bugsnag.getPlugin('vue');

const app = createApp(App)
  .directive('mask', vMaskV3)
  .use(bugsnagVue)
  .use(IonicVue, {
    mode: 'ios',
  })
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_API_KEY,
      libraries: 'places',
    }
  })
  .use(router)
  .use(store);

const initData = async () => {
  if (store.getters.currentUser) {
    await store.dispatch('fetchStartedCoursesAcademy');
    await store.dispatch('fetchFinishedCoursesAcademy');
  }
};

router.isReady().then(async () => {
  await initData();
  app.mount('#app');
});

app.component('LrtLoadingOverlay', LrtLoadingOverlay);

defineCustomElements(window);
