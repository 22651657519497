import { createRouter, createWebHistory } from '@ionic/vue-router';
import { alertText } from '@/helperFns/alerts';
import { checkAppStatus } from '@/helperFns/appStatus';
import store from '../store';

import educationRoutes from '@/router/routes/Education';
import academyRoutes from '@/router/routes/Academy';
import availabilityRoutes from '@/router/routes/Availability';
import teamSettingsRoutes from '@/router/routes/TeamSettings';
import settingsRoutes from '@/router/routes/Settings';
import clubRoutes from '@/router/routes/Club';
import chatRoutes from '@/router/routes/Chat';
import activityRoutes from '@/router/routes/Activity';
import profileRoutes from '@/router/routes/Profile';
import infoRoutes from '@/router/routes/Info';
import onboardingRoutes from '@/router/routes/Onboarding';
import agreementsRoutes from '@/router/routes/Agreements';
import productsRoutes from '@/router/routes/Products';
import loginRegisterRoutes from '@/router/routes/LoginRegister';
import contactsRoutes from '@/router/routes/Contacts';
import organizationsRoutes from '@/router/routes/Organizations';

const routes = [
  {
    path: '/',
    name: 'splash',
    component: () => import('@/views/AppSplash.vue'),
    meta: {
      auth: false,
      isMenuHidden: true,
    },
  },
  {
    path: '/app-update',
    name: 'app-update',
    component: () => import('@/views/AppUpdate.vue'),
    meta: {
      auth: false,
      isMenuHidden: true,
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/AppSplash.vue'),
    meta: {
      auth: false,
      isMenuHidden: true,
    },
  },
  {
    path: '/landing',
    name: 'landing',
    component: () => import('@/views/Landing.vue'),
    meta: {
      auth: false,
      isMenuHidden: true,
    },
  },
  {
    path: '/landing-customer',
    name: 'landing-customer',
    component: () => import('@/views/LandingCustomer.vue'),
    meta: {
      auth: false,
      isMenuHidden: true,
    },
  },
  {
    path: '/components',
    name: 'components',
    component: () => import('@/views/Components.vue'),
    meta: {
        isMenuHidden: true
    }
  },
  ...loginRegisterRoutes,
  ...onboardingRoutes,
  ...infoRoutes,
  ...teamSettingsRoutes,
  ...settingsRoutes,
  ...availabilityRoutes,
  ...academyRoutes,
  ...activityRoutes,
  ...educationRoutes,
  ...chatRoutes,
  ...profileRoutes,
  ...agreementsRoutes,
  ...productsRoutes,
  ...clubRoutes,
  ...contactsRoutes,
  ...organizationsRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const isUserAuthenticated =
    typeof localStorage.getItem('lrt_tkn') === 'string' &&
    typeof localStorage.getItem('userRole') === 'string';
  const isUserEducator = localStorage.getItem('userRole') === 'educator';

  /**
   * Unathorized users will be redirected to welcome page
   * or pages with meta.auth: false.
   */
  if (!isUserAuthenticated) {
    if (to.path !== '/' && !to.meta.auth) {
      next();
    } else {
      next({ path: '/welcome' });
    }

    return;
  }

  /**
   * Get app status
   */
  try {
    // Only check if app status if value is not set
    if (store.getters['hasCorrectVersion'] === undefined)
      await checkAppStatus();
  } catch (error) {
    alertText('Applikationens version kunde inte identifieras.');
    localStorage.clear();
    next('/');
    return;
  }

  /**
   * Redirect to update page if app is outdated
   */
  if (!store.getters['hasCorrectVersion']) {
    if (to.path !== '/app-update') {
      next({ path: '/app-update' });
      return;
    }
    next();
    return;
  }

  /**
   * Handle specific route redirects.
   */
  switch (to.path) {
    // Skip these pages when user is authenticated
    case '/':
    case '/home':
    case '/login':
    case '/login-customer':
    case '/register':
    case '/register-customer':
      next({ path: '/club' });
      break;

    // Redirect to correct profile page
    case '/profile':
      isUserEducator ? next() : next({ path: '/profile-customer' });
      break;

    // Check onboarding when entering first view
    case '/club':
      store.getters.currentUser.meta?.is_onboarding_completed ? next() : next({ path: '/onboarding' });
      break;

    default:
      next();
      break;
  }
});

export default router;
