export default [
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/activity/Index.vue'),
    meta: {
      auth: true
    },
    children: [
      {
        path: '/:id',
        name: 'activity-single',
        component: () => import('@/views/activity/Single.vue'),
        meta: {
          auth: true
        }
      }
    ]
  },
  {
    path: '/activity/:id',
    component: () => import('@/views/activity/Single.vue'),
    params: true,
    meta: {
      auth: true
    }
  },
  {
    path: '/activity/payment/:id',
    component: () => import('@/views/activity/SinglePayment.vue'),
    params: true,
    meta: {
      auth: true
    }
  },
  {
    path: '/activity?tab=:tab',
    component: () => import('@/views/activity/Index.vue'),
    params: true,
    meta: {
      auth: true
    }
  }
];