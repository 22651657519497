import axios from "axios";

const state = {
  loginToken: null,
  user: null,
}

const getters = {
  loginToken({ loginToken }) {
    return loginToken || localStorage.getItem("lrt_tkn");
  },
  currentUser({ user }) {
    return user || JSON.parse(localStorage.getItem("lrt_user"));
  },
  currentUserRole({ user }) {
    const savedUser = user || JSON.parse(localStorage.getItem("lrt_user"));
    if (!savedUser) { 
        return null;
    }
    return savedUser.role.toLowerCase();
  },
}

const mutations = {
  loginToken(state, token) {
    state.loginToken = token;
    localStorage.setItem("lrt_tkn", token);
  },
  currentUser(state, data) {
    state.user = data;
    localStorage.setItem("lrt_user", JSON.stringify(data));
  },
}

const actions = {
  initToken(context) {
    let _token = localStorage.getItem("lrt_tkn");
    if(_token) {
      context.commit("loginToken", _token);
    } else {
      console.error('App token is not set. Please log in.');
    }
  },
  login(context, request) {
    return new Promise((resolve, reject) => {
      axios
        .post("educator/login", {
          email: request.email,
          password: request.password
        })
        .then(async ({ data }) => {
          context.commit("loginToken", data.app_token);
          context.commit("currentUser", data);
          await context.dispatch("fetchStartedCoursesAcademy");
          await context.dispatch("fetchFinishedCoursesAcademy");

          resolve();
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  loginCustomer(context, request) {
    return new Promise((resolve, reject) => {
      axios
        .post("customer/login", {
          email: request.email,
          password: request.password
        })
        .then(async ({ data }) => {
          context.commit("loginToken", data.app_token);
          context.commit("currentUser", data);
          await context.dispatch("fetchStartedCoursesAcademy");
          await context.dispatch("fetchFinishedCoursesAcademy");

          resolve();
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  register(context, request) {
    return new Promise((resolve, reject) => {
      axios
        .post("educator/register", request)
        .then(({ data }) => {
          // Login user after a successful registration
          context.dispatch("login", {
            email: data.email,
            password: request.password
          }).then(() => {
            resolve();
          }).catch(err => {
            console.log("login chain error ",err);
            reject(err);
          })

        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  registerCustomer(context, request) {
    return new Promise((resolve, reject) => {
      axios
        .post("customer/register", request)
        .then(({ data }) => {
          // Login user after a successful registration
          context.dispatch("loginCustomer", {
            email: data.email,
            password: request.password
          }).then(() => {
            resolve();
          }).catch(err => {
            console.log("login chain error ",err);
            reject(err);
          })

        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  fetchUser(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/educator/users/${context.getters.currentUser.id}`)
        .then(({ data }) => {
          if (data) {
            context.commit('currentUser', data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  resetStateAuth(context) {
    context.commit("loginToken", null);
    context.commit("currentUser", null);
    localStorage.removeItem("lrt_tkn");
    localStorage.removeItem("lrt_user");
  }
}

export { state, getters, mutations, actions }
