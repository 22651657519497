import axios from 'axios';

const state = {
  chatList: [],
  chatCurrent: {},
  unreadMessages: false
};

const getters = {
  chatList({ chatList }) {
    return chatList;
  },
  chatCurrent({ chatCurrent }) {
    return chatCurrent;
  },
  unreadMessages({ unreadMessages }) {
    return unreadMessages;
  }
};

const mutations = {
  setChatList(state, data) {
    state.chatList = data;
  },
  setCurrentChat(state, data) {
    state.chatCurrent = data;
  },
  setUnreadMessages(state, data) {
    state.unreadMessages = data;
  }
};

const actions = {
  fetchChatList(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('educator/chat/get-contacts', {
          type: payload.type,
        })
        .then(({ data }) => {
          context.commit('setChatList', data.contacts);
          data.contacts.forEach((item) => {
            if (!item.seen && context.getters.currentUser.id !== item.from_id) {
              context.commit('setUnreadMessages', true);
            }
          });
          resolve(data.contacts);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  fetchChatMessages(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('educator/chat/fetch-messages', {
          type: payload.type,
          id: payload.sender_id,
        })
        .then(({ data }) => {
          context.commit('setCurrentChat', data);
          resolve(data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  sendMessage(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/educator/chat/send-message', {
          type: payload.type,
          id: payload.id,
          message: payload.message
        })
        .then(({ data }) => {
          //context.commit('setChatList', data);
          resolve(data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  setSeenStatus(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/educator/chat/set-seen', {
          id: payload.id
        })
        .then(({ data }) => {
          //context.commit('setChatList', data);
          resolve(data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
};

export {
  state,
  getters,
  actions,
  mutations
}