export default [
  {
    path: "/availability/step-1",
    name: "availability-step-1",
    component: () => import("@/views/availability/Step1.vue"),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: "/availability/step-2",
    name: "availability-step-2",
    component: () => import("@/views/availability/Step2.vue"),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: "/availability/step-3",
    name: "availability-step-3",
    component: () => import("@/views/availability/Step3.vue"),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  }
]