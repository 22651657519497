export default [
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/views/organizations/Index.vue'),
    meta: {
      auth: true,
      isMenuHidden: true,
    },
  },
  {
    path: '/organizations/:id',
    name: 'organizations-show',
    component: () => import('@/views/organizations/Single.vue'),
    params: true,
    meta: {
      auth: true,
      isMenuHidden: true,
    },
  },
  {
    path: '/organizations/:orgId/group/:id',
    name: 'groups-show',
    component: () => import('@/views/groups/Single.vue'),
    params: true,
    meta: {
      auth: true,
      isMenuHidden: true,
    },
  },
];
