import axios from "axios";

const state = {
  userSettings: undefined
}

const getters = {
  adsBasedOnCookies({ userSettings }) {
    return userSettings.find(
      (s) => s.key === "cookies"
    ).value
  },
  chatNotifications({ userSettings }) {
    return userSettings.find(
      (s) => s.key === "notifications"
    ).value.chat;
  },
  newsNotifications({ userSettings }) {
    return userSettings.find(
      (s) => s.key === "notifications"
    ).value.news;
  }
}

const mutations = {
  userSettings(state, data) {
    state.userSettings = data;
  },
}

const actions = {
  async updatePayment({ commit, getters }, paymentSetting) {
    const currentUser = {...getters.currentUser};
    currentUser.profile = {...currentUser.profile, ...paymentSetting};
    const { data: updatedUser } = await axios.put(`educator/users/` + currentUser.id, paymentSetting)
    commit("currentUser", updatedUser);

    return updatedUser
  },
  async fetchUserSetting({ commit }) {
    const { data: userSettings } = await axios.get(`educator/user-settings/`)
    commit("userSettings", userSettings);

    return userSettings
  },
  async updateNewsNotifications({ commit, state }, newValue) {
    const userSettings = [...state.userSettings];
    userSettings.find(s => s.key === 'notifications').value.news = newValue
    const { data: updatedUserSettings } = await axios.post(`educator/user-settings/`, userSettings)
    commit("userSettings", updatedUserSettings);

    return updatedUserSettings
  },
  async updateChatNotifications({ commit, state }, newValue) {
    const userSettings = [...state.userSettings];
    userSettings.find(s => s.key === 'notifications').value.chat = newValue
    const { data: updatedUserSettings } = await axios.post(`educator/user-settings/`, userSettings)
    commit("userSettings", updatedUserSettings);

    return updatedUserSettings
  },
  async updateCookies({ commit, state }, newValue) {
    const userSettings = [...state.userSettings];
    userSettings.find(s => s.key === 'cookies').value = newValue
    const { data: updatedUserSettings } = await axios.post(`educator/user-settings/`, userSettings)
    commit("userSettings", updatedUserSettings);

    return updatedUserSettings
  },

}

export { state, getters, mutations, actions }