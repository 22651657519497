import axios from "axios";

const state = {
  startedCoursesAcademy: null,
  finishedCoursesAcademy: null,
};

/**
 * Getters use JSON.parse(JSON.stringify()) to deep clone objects
 */
const getters = {
  startedCoursesAcademy(state) {
    return JSON.parse(JSON.stringify(state.startedCoursesAcademy));
  },
  finishedCoursesAcademy(state) {
    return JSON.parse(JSON.stringify(state.finishedCoursesAcademy));
  },
  allCoursesAcademy(state) {
    return JSON.parse(JSON.stringify(state.startedCoursesAcademy || [])).concat(
      JSON.parse(JSON.stringify(state.finishedCoursesAcademy || []))
    );
  },
  courseByIdAcademy: (state, getters) => id => {
    return getters.allCoursesAcademy.find(course => course.id == id);
  },
  courseByCourseIdAcademy: (state, getters) => id => {
    return getters.allCoursesAcademy.find(course => course.course_id === id);
  },
};

const mutations = {
  setStartedCoursesAcademy(state, payload) {
    state.startedCoursesAcademy = payload.data;
  },
  setFinishedCoursesAcademy(state, payload) {
    state.finishedCoursesAcademy = payload.data;
  },
};

// Status enums:
// STARTED
// FINISHED
// PENDING
// Category enums:
// ACADEMY
// EDUCATION
const actions = {
  fetchStartedCoursesAcademy({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(`${getters.currentUser.role.toLowerCase()}/me/courses/`, {
        params: { status: "STARTED", category: "ACADEMY" }
      })
      .then(data => {
        commit('setStartedCoursesAcademy', data);
        resolve(data);
      })
      .catch(error => {
        console.log("fetchStartedCoursesAcademy", error);
        reject(error);
      });
    });
  },
  fetchFinishedCoursesAcademy({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(`${getters.currentUser.role.toLowerCase()}/me/courses/`, {
        params: { status: "FINISHED", category: "ACADEMY" }
      })
      .then(data => {
        commit('setFinishedCoursesAcademy', data);
        resolve(data);
      })
      .catch(error => {
        console.log("fetchFinishedCoursesAcademy", error);
        reject(error);
      });
    });
  },
};

export { state, getters, mutations, actions };
