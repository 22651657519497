import axios from "axios";

const state = {
  orders: null,
  products: null,
  product: null,
  shoppingCart: null,
}

const getters = {
  orders({ orders }) {
    return orders || JSON.parse(localStorage.getItem("lrt_orders"));
  },
  products({ products }) {
    return products || JSON.parse(localStorage.getItem("lrt_products"));
  },
  product({ product }) {
    return product || JSON.parse(localStorage.getItem("lrt_product"));
  },
  shoppingCart({ shoppingCart }) {
    return shoppingCart || JSON.parse(localStorage.getItem("lrt_shoppingCart"));
  },
}

const mutations = {
  orders(state, data) {
    state.orders = data;
    localStorage.setItem("lrt_orders", JSON.stringify(data));
  },
  products(state, data) {
    state.products = data;
    localStorage.setItem("lrt_products", JSON.stringify(data));
  },
  product(state, data) {
    state.product = data;
    localStorage.setItem("lrt_product", JSON.stringify(data));
  },
  shoppingCart(state, data) {
    state.shoppingCart = data;
    localStorage.setItem("lrt_shoppingCart", JSON.stringify(data));
  },
}

const actions = {
  fetchProducts(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("educator/products")
        .then(({ data }) => {
          let products = data.map(product => {
            if (product.properties && product.properties.length) {
              let properties = product.properties.map(property => {
                let options;

                if (property.attributes && property.attributes.options && property.attributes.options.length) {
                  options = property.attributes.options.map(option => {
                    return {
                      title: option.attributes.title,
                      value: option.attributes.title
                    }
                  });
                }

                return {
                  title: property.attributes.title,
                  options: options
                };
              });

              product.properties = properties;
              return product;
            }

            return product;
          });

          context.commit('products', products);
          resolve(products);
        })
        .catch(error => {
          console.log("error2", error)
          reject(error.response);
        });
    });
  },
  fetchOrders(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("educator/orders")
        .then(({ data: { data } }) => {
          context.commit('orders', data);
          resolve(data);
        })
        .catch(error => {
          console.log("error", error)
          reject(error.response);
        });
    });
  },
  shoppingCart(context, request) {
    return new Promise((resolve) => {
      let cart = context.getters.shoppingCart;
      cart = {
        ...context.getters.shoppingCart,
        ...request
      }
      // cart.push(request);
      context.commit("shoppingCart", cart);
      resolve();
    });
  },
  orderItem(context, request) {
    return new Promise((resolve, reject) => {
      axios
        .post("educator/orders", request)
        .then(({ data }) => {
          resolve({...data});
        })
        .catch(error => {
          console.log("error2", error)
          reject(error.response);
        });
    });
  }
}

export { state, getters, mutations, actions }
