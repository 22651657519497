import axios from 'axios';

const state = {
  feed: {
    news: [],
    featuredCats: []
  },
  topic: Object,
  topics: [],
  contentType: {},
  likes: [],
  comments: []
};

const getters = {
  contentTypeByType: ({contentType}) => (type) => {
    return contentType[type];
  },
  contentType({contentType}) {
    return contentType;
  },
  feed({ feed }) {
    return feed;
  },
  topic({ topic }) {
    return topic;
  },
  topics({ topics }) {
    return topics;
  },
  likes({ likes }) {
    return likes.data;
  },
  comments({ comments }) {
    return comments;
  },
  commentsData({ comments }) {
    return comments.data;
  }
};

const mutations = {
  resetTopic(state) {
    state.topic = null;
  },
  resetTopics(state) {
    state.topics = null;
  },
  setContentType(state, data) {
    state.contentType[data.key] = data.data;
  },
  setFeedNews(state, data) {
    state.feed.news = data;
  },
  setFeaturedCats(state, data) {
    state.feed.featuredCats = data;
  },
  setTopic(state, data) {
    state.topic = data;
  },
  setTopics(state, data) {
    state.topics = data;
  },
  setLikes(state, data) {
    state.likes = data;
  },
  setComments(state, data) {
    state.comments = data;
  },
};

const actions = {
  resetTopic(context) {
    context.commit('resetTopic');
  },
  resetTopics(context) {
    context.commit('resetTopics');
  },
  fetchFeed(context) {
    const feedNews = axios
      .get(`${context.getters.currentUser.role.toLowerCase()}/club-topics/`, { params: {
        content_type: 'news',
        per_page: 5,
        order_by: 'created_at',
        sort_by: 'desc'
      } }).then((response) => {
        context.commit('setFeedNews', response.data.data);
      });

    const feedFeaturedCats = axios
      .get(`${context.getters.currentUser.role.toLowerCase()}/club-topic-categories/`).then((response) => {
        const featuredCats = response.data.data.filter((el) => el.featured);
        context.commit('setFeaturedCats', featuredCats);
      });
    
    return Promise.all([feedNews, feedFeaturedCats]);
  },
  fetchTopic(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context.getters.currentUser.role.toLowerCase()}/club-topics/${payload.id}`)
        .then(({ data }) => {
          context.commit('setTopic', data);
          resolve(data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  fetchTopics(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context.getters.currentUser.role.toLowerCase()}/club-topics/`, { params: payload })
        .then(({ data }) => {
          context.commit('setTopics', data);
          resolve(data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  getContentType(context, payload, force = false) {
    return new Promise((resolve, reject) => {
      if(context.state.contentType[payload.content_type] && !force) {
        resolve(context.state.contentType[payload.content_type])
      } else {
        setTimeout(() => {
          axios
            .get(`${context.getters.currentUser.role.toLowerCase()}/club-topics/`, { params: payload })
            .then(({ data }) => {
              context.commit('setContentType', { key: payload.content_type, data: data });
              resolve(data);
            })
            .catch(err => {
              reject(err.response);
            });
        }, 300);
      }
    });
  },
  topicFind(context) {
    let topicsKeys = Object.keys(context.state.topics);
    
    if(!topicsKeys.length) {
      context.dispatch('fetchContentType', {
        page: 1, 
        per_page: 5, 
        order_by: 'created_at',
        sort_by: 'desc',
        content_type: 'news',
      }).then(() => {
        topicsKeys = Object.keys(context.state.contentType);
      });
    }
    return false;
  },
  fetchContentType(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context.getters.currentUser.role.toLowerCase()}/club-topics/`, { params: payload })
        .then(({ data }) => {
          context.commit('setContentType', { key: payload.content_type, data: data });
          resolve(data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  fetchLikes(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context.getters.currentUser.role.toLowerCase()}/club-topics/${payload.id}/like`)
        .then(({ data }) => {
          context.commit('setLikes', data);
          resolve(data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  fetchComments(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context.getters.currentUser.role.toLowerCase()}/club-topics/${payload.id}/comments`, { params: {
          sort_by: 'desc',
          order_by: 'created_at'
        } })
        .then(({ data }) => {
          context.commit('setComments', data);
          resolve(data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

export {
  state,
  getters,
  actions,
  mutations
}