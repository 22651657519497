export default [
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/contacts/Index.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  }
];
