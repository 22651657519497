export default [
  {
    path: '/education',
    name: 'education',
    component: () => import('@/views/education/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/education?tab=:tab',
    component: () => import('@/views/education/Index.vue'),
    params: true,
    meta: {
      auth: true
    }
  },
  {
    path: '/education/requests/success',
    name: 'education-requests-success',
    component: () => import('@/views/education/requests/SuccessRequest.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/education/requests/success-accept',
    name: 'education-requests-success-accept',
    component: () => import('@/views/education/requests/SuccessAccept.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/education/requests/success-denied',
    name: 'education-requests-success-denied',
    component: () => import('@/views/education/requests/SuccessDenied.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/education/requests/:id',
    name: 'education-request',
    component: () => import('@/views/education/requests/Single.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/education/requests/create',
    name: 'create-request',
    component: () => import('@/views/education/requests/Create.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/education/session/:id',
    component: () => import('@/views/education/session/Single.vue'),
    meta: {
      auth: true
    }
  },
];