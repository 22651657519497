export default [
  {
    path: '/memberpass',
    name: 'memberpass',
    component: () => import('@/views/profile-customer/Memberpass.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/profile-customer',
    name: 'profile-customer',
    component: () => import('@/views/profile-customer/Index.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Index.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/edit-profile',
    name: 'edit-profile',
    component: () => import('@/views/profile/Edit.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/edit-profile-customer',
    name: 'edit-profile-customer',
    component: () => import('@/views/profile-customer/Edit.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/profile-customer/ChangePassword.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/presentation',
    name: 'presentation',
    component: () => import('@/views/Presentation.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  }
];