import axios from 'axios';

const state = {
  group: {},
  participants: [],
  contactPersons: []
};

const getters = {
  group({ group }) {
    return group;
  },
  groupParticipants({ participants }) {
    return participants;
  },
  groupContactPersons({ contactPersons }) {
    return contactPersons;
  }
};

const mutations = {
  setGroup(state, data) {
    state.group = data
  },
  setParticipants(state, data) {
    state.participants = data;
  },
  setGroupContactPersons(state, data) {
    state.contactPersons = data;
  }
};

const actions = {
  updateGroup(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`educator/groups/${payload.id}`, {
          ...payload
        })
        .then(({ data }) => {
          context.commit('setGroup', data);
          resolve();
        })
        .catch(() => reject());
    });
  },
  groupParticipantFetchAll(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`educator/groups/${id}/participants`)
        .then(({ data }) => {
          context.commit('setParticipants', data);
          resolve(data);
        })
        .catch(() => reject())
    });
  },
  groupParticipantAdd(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`educator/groups/${payload.group_id}/participants`, payload)
        .then(({ data }) => {
          context.dispatch('groupParticipantFetchAll', payload.group_id);
          resolve(data);
        })
        .catch(() => reject())
    });
  },
  groupParticipantUpdate(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`educator/groups/${payload.group_id}/participants/${payload.participant_id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => reject());
    });
  },
  groupParticipantDelete(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`educator/groups/${payload.group_id}/participants/${payload.participant_id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => reject());
    });
  },
  groupContactPersonFetchAll(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`educator/groups/${id}/contact-persons`)
        .then(({ data }) => {
          context.commit('setGroupContactPersons', data.data);
          resolve(data);
        })
        .catch(() => reject());
    });
  },
  groupContactPersonAdd(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`educator/groups/${payload.group_id}/contact-persons`, payload)
        .then(({ data }) => {
          context.dispatch('groupContactPersonFetchAll', payload.group_id);
          resolve(data);
        })
        .catch(() => reject());
    });
  },
  groupContactPersonUpdate(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`educator/groups/${payload.group_id}/contact-persons/${payload.person_id}`, {
          ...payload.data
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => reject());
    });
  }
};

export {
  state,
  getters,
  actions,
  mutations
}