const state = {
  version: undefined,
  hasCorrectVersion: undefined,
  hasUnreadMessages: undefined,
};

const getters = {
  version({ version }) {
    return version;
  },
  hasCorrectVersion({ hasCorrectVersion }) {
    return hasCorrectVersion;
  },
  hasUnreadMessages({ hasUnreadMessages }) {
    return hasUnreadMessages;
  },
};

const mutations = {
  setVersion(state, data) {
    state.version = data;
  },
  setHasCorrectVersion(state, data) {
    state.hasCorrectVersion = data;
  },
  setHasUnreadMessages(state, data) {
    state.hasUnreadMessages = data;
  },
};

const actions = {
};

export { state, getters, mutations, actions };
