export default [
  {
    path: "/onboarding",
    name: "onboarding",
    component: () => import("@/views/Onboarding.vue"),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
]