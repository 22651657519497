export default [
  {
    path: "/team-settings",
    name: "team-settings",
    component: () => import("@/views/team-settings/Index.vue"),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: "/team-settings/organizations/:id/create-group",
    name: "team-settings-create-group",
    component: () => import("@/views/team-settings/CreateGroup.vue"),
    params: true,
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: "/team-settings/organizations/:id/address",
    name: "team-settings-organization-address",
    component: () => import("@/views/team-settings/OrganizationAddress.vue"),
    params: true,
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: "/team-settings/groups/:id",
    name: "team-settings-group",
    component: () => import("@/views/team-settings/Group.vue"),
    params: true,
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: "/team-settings/groups/:id/edit",
    name: "team-settings-group-edit",
    component: () => import("@/views/team-settings/EditGroup.vue"),
    params: true,
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
]