import axios from "axios";
import store from '../store';
import packageInfo from '../../package.json';

/**
 * Get app status
 * @returns object || Error
 */
const checkAppStatus = async () => {
  try {
    store.commit('setVersion', packageInfo.version);

    const { data } = await axios.get(`${store.getters.currentUserRole}/status`);

    store.commit('setHasCorrectVersion', data.has_correct_version);
    store.commit('setHasUnreadMessages', data.has_unread_messages);

    return data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export { checkAppStatus };
