import axios from 'axios';

const state = {
  organization: {}
};

const getters = {
  organization({ organization }) {
    return organization;
  }
};

const mutations = {
  setOrganization(state, data) {
    state.organization = data;
  }
};

const actions = {
  updateOrganization(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`educator/organizations/${payload.id}`, {
          ...payload
        })
        .then(({ data }) => {
          context.commit('setOrganization', data);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
};

export {
  state,
  getters,
  mutations,
  actions
}