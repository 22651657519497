import axios from "axios";

const state = {
  profileImage: null,
}

const getters = {}

const mutations = {
  setProfileImage(state, payload) {
    state.profileImage = payload;
  }
}

const actions = {
  updateProfile(context, request) {
    return new Promise((resolve, reject) => {
      axios
        .put(`educator/users/${context.getters.currentUser.id}`, request)
        .then(({ data }) => {
          context.commit("currentUser", data);
          resolve(data);
        })
        .catch(error => {
          console.log("error", error)
          reject(error.response);
        });
    });
  },
  updateProfileImage(context, data) {
    context.commit("setProfileImage", data);
  },
}

export { state, getters, mutations, actions }