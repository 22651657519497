import axios from "axios";
import store from "../store";
import packageInfo from '../../package.json';

// axios.defaults.showLoader = true;

// Set api url based on enviroment
axios.defaults.baseURL = process.env.VUE_APP_Api_Url;

axios.interceptors.request.use(function (config) {
  // store.commit("isLoading", true);

  if(!config.url.includes('http')){
    // Set token before each request
    config.headers['Authorization'] = `Bearer ${store.getters.loginToken}`;
    config.headers['Content-Type'] = 'application/json';
    config.headers['App-Version'] = packageInfo.version;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default axios;