export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Index.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/settings/payment',
    name: 'payment-settings',
    component: () => import('@/views/settings/Payment.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/settings/secrecy',
    name: 'secrecy-settings',
    component: () => import('@/views/settings/Secrecy.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/settings/criminal-record',
    name: 'criminal-record',
    component: () => import('@/views/settings/CriminalRecord.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/settings/partner',
    name: 'partner',
    component: () => import('@/views/settings/Partner.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/settings/about-version',
    name: 'about-version',
    component: () => import('@/views/settings/AboutVersion.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/settings/terms',
    name: 'terms',
    component: () => import('@/views/settings/Terms.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/settings/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/settings/PrivacyPolicy.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/settings/tech-support',
    name: 'tech-support',
    component: () => import('@/views/settings/TechSupport.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/settings/remove-account',
    name: 'remove-account',
    component: () => import('@/views/settings/RemoveAccount.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
];
