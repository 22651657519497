export default [
  {
    path: "/academy",
    name: "academy",
    // redirect: "/academy/catalouge",
    component: () => import("@/views/academy/Index.vue"),
    meta: {
      auth: true
    },
    children: [
      {
        name: 'academy-courses',
        path: "courses",
        props: true,
        component: () => import("@/views/academy/courses/Index.vue"),
        meta: {
          auth: true
        }
      },
    ]
  },
  {
    path: "/academy/catalouge/:id",
    component: () => import("@/views/academy/catalouge/Single.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/material/:id",
    component: () => import("@/views/academy/material/Single.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id",
    component: () => import("@/views/academy/courses/Single.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/article/:item_id",
    alias: '/education/courses/:id/article/:item_id',
    name: "course-item-article",
    component: () => import("@/views/academy/courses/ItemArticle.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/session/:item_id",
    alias: '/education/courses/:id/session/:item_id',
    name: "course-item-session",
    component: () => import("@/views/academy/courses/ItemArticle.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/evaluation/:item_id",
    alias: '/education/courses/:id/evaluation/:item_id',
    name: "course-item-evaluation",
    component: () => import("@/views/academy/courses/ItemEvaluation.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/evaluation_results/:item_id",
    alias: '/education/courses/:id/evaluation_results/:item_id',
    name: "course-item-evaluation_results",
    component: () =>
      import("@/views/academy/courses/ItemEvaluationResults.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/take_note/:item_id",
    alias: '/education/courses/:id/take_note/:item_id',
    name: "course-item-take_note",
    component: () => import("@/views/academy/courses/ItemTakeNote.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/take_photo/:item_id",
    alias: '/education/courses/:id/take_photo/:item_id',
    name: "course-item-take_photo",
    component: () => import("@/views/academy/courses/ItemTakePhoto.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/additional_info/:item_id",
    alias: '/education/courses/:id/additional_info/:item_id',
    name: "course-item-additional_info",
    component: () => import("@/views/academy/courses/ItemArticle.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/continuation_plan/:item_id",
    alias: '/education/courses/:id/continuation_plan/:item_id',
    name: "course-item-continuation_plan",
    component: () => import("@/views/academy/courses/ItemArticle.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/continuation_plan_results/:item_id",
    alias: '/education/courses/:id/continuation_plan_results/:item_id',
    name: "course-item-continuation_plan_results",
    component: () => import("@/views/academy/courses/ItemArticle.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/team_information/:item_id",
    alias: '/education/courses/:id/team_information/:item_id',
    name: "course-item-team_information",
    component: () => import("@/views/academy/courses/ItemTeamInformation.vue"),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/academy/courses/:id/photo_results/:item_id",
    alias: '/education/courses/:id/photo_results/:item_id',
    name: "course-item-photo_results",
    component: () => import("@/views/academy/courses/ItemPhotoResults.vue"),
    props: true,
    meta: {
      auth: true
    }
  }
]