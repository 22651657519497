import axios from 'axios';

const state = {
  // TODO: Check if presenatations is not used
  presentations: [],
  presentation: null
};

const getters = {
  presentations({ presentations }) {
    return presentations;
  },
  presentation({ presentation }) {
    return presentation;
  }
};

const mutations = {
  presentations(state, data) {
    state.presentations = data;
  },
  presentation(state, data) {
    state.presentation = data;
  }
};

const actions = {
  fetchPresentation(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`educator/presentations/${406}`)
        .then(({ data }) => {
          context.commit('presentation', data);
          resolve(data);
        })
        .catch(error => {
          console.log('error', error);
          reject(error.response);
        });
    });
  },
  createPresentation(context, request) {
    return new Promise((resolve, reject) => {
      if (!request || request === '') {
        reject('No request');
        return false;
      }

      axios
        .post('educator/presentations', request)
        .then(({ data }) => {
          context.commit('presentation', data);
          resolve(data);
        })
        .catch(error => {
          console.log('error', error);
          reject(error.response);
        });
    });
  },
  updatePresentation(context, request) {
    return new Promise((resolve, reject) => {
      if (!request || request === '') {
        reject('No request');
        return false;
      }

      // If no id is present, then create a new presentation.
      // Else update the presentation.
      if (!request.id) {
        context.dispatch('createPresentation', request)
          .then(data => {
            resolve(data);
          });
      } else {
        axios
          .put(`educator/presentations/${request.id}`, request)
          .then(({ data }) => {
            context.commit('presentation', data);
            resolve(data);
          })
          .catch(error => {
            console.log('error', error);
            reject(error.response);
          });
      }
    });
  }
};

export { state, getters, mutations, actions };
