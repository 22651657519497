import axios from "axios";

const state = {
  availabilities: [],
}

const getters = {
  availabilities({ availabilities }) {
    return availabilities;
  }
}

const mutations = {
  availabilities(state, data) {
    state.availabilities = data;
  }
}

const actions = {
  fetchAvailability(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("educator/availabilities")
        .then(({ data }) => {
          context.commit("availabilities", data);
          resolve(data);
        })
        .catch(error => {
          console.log("error2", error)
          reject(error.response);
        });
    });
  },
  addAvailability(context, request) {
    return new Promise((resolve, reject) => {
      axios
        .post("educator/availabilities/batch", request)
        .then(({ data }) => {
          // context.commit("availabilities", data);
          resolve(data);
        })
        .catch(error => {
          console.log("error2", error)
          reject(error.response);
        });
    });
  },
}

export { state, getters, mutations, actions }