export default [
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/Index.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/product/:id',
    name: 'product-details',
    component: () => import('@/views/products/Details.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  }
];