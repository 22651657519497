export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      auth: false,
      isMenuHidden: true
    }
  },
  {
    path: "/login-customer",
    name: "login-customer",
    component: () => import("@/views/LoginCustomer.vue"),
    meta: {
      auth: false,
      isMenuHidden: true
    }
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/ResetPassword.vue"),
    meta: {
      auth: false,
      isMenuHidden: true
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Register.vue"),
    meta: {
      auth: false,
      isMenuHidden: true
    }
  },
  {
    path: "/register-customer",
    name: "register-customer",
    component: () => import("@/views/RegisterCustomer.vue"),
    meta: {
      auth: false,
      isMenuHidden: true
    }
  }
]