<template>
  <div
    v-if="active"
    class="h-full absolute top-0 bottom-0 left-0 right-0 text-white flex items-center bg-black z-50"
  >
    <div class="mx-auto">
      <div class="my-auto">
        <figure class="w-32">
          <img class="w-full h-auto" src="/assets/images/loader2.svg" alt="" />
        </figure>
        <div class="text-center text-xs">Laddar...</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    let active = computed(() => store.getters['isLoading']);

    return {
      active
    };
  }
}
</script>