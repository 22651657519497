import axios from "axios";

const state = {
  agreements: null,
  agreement: null,
  allAgreementsSigned: false
}

const getters = {
  agreements({ agreements }) {
    return agreements || JSON.parse(localStorage.getItem("lrt_agreements"));
  },
  agreement({ agreement }) {
    return agreement || JSON.parse(localStorage.getItem("lrt_agreement"));
  },
  allAgreementsSigned({ allAgreementsSigned }) {
    return allAgreementsSigned || JSON.parse(localStorage.getItem("lrt_allAgreementsSigned"));
  },
}

const mutations = {
  agreements(state, data) {
    state.agreements = data;
    localStorage.setItem("lrt_agreements", JSON.stringify(data));
  },
  agreement(state, data) {
    state.agreement = data;
    localStorage.setItem("lrt_agreement", JSON.stringify(data));
  },
  allAgreementsSigned(state, data) {
    state.agreement = data;
    localStorage.setItem("lrt_allAgreementsSigned", JSON.stringify(data));
  },
}

const actions = {
  fetchAgreements(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context.getters.currentUser.role.toLowerCase()}/agreements`)
        .then(({ data }) => {
          context.commit("agreements", data);
          resolve(data);
        })
        .catch(error => {
          console.log("error2", error)
          reject(error.response);
        });
    });
  },
  signAgreement(context, request) {
    return new Promise((resolve, reject) => {
      axios
        .post(`educator/agreements/sign/${request.id}`, {
          ...request.form
        })
        .then((data) => {
          // Step 1
          // Get agreements
          context.dispatch("fetchAgreements")
            .then(res => {
              // Step 2 check if all documents are signed
              let count = 0;
              for (let i = 0; res.length > i; i++) {
                if (res[i].status === "signed" && count != res.length) {
                  count++
                }
              }

              resolve(data);
            })
        })
        .catch(error => {
          console.log("error2", error)
          reject(error.response);
        });
    });
  },
}

export { state, getters, mutations, actions }
