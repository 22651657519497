export default [
  {
    path: '/club',
    name: 'club',
    component: () => import('@/views/club/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/news/',
    component: () => import('@/views/club/news/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/club/topic/:id',
    name: 'club-topic-item',
    component: () => import('@/views/club/topics/Single.vue'),
    meta: {
      auth: true,
      isMenuHidden: true
    }
  },
  {
    path: '/club/categories/:category',
    component: () => import('@/views/club/categories/Index.vue'),
    meta: {
      auth: true
    }
  }
];